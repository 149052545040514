import React from "react"

import { useSanity } from "../../hooks/useSanity"
import { useSection } from "../../hooks/useSection"

export const withWishlist = Component => ({ name = `Wishlist`, page, ...props }) => {
  const { heroNormaliser } = useSanity()
  const { transformSection } = useSection()

  const content = {
    ...page,
    heroBanner: heroNormaliser(page?.heroBanner),
  }
  const hero = transformSection(content?.heroBanner)

  Component.displayName = name
  return <Component {...props} {...content} hero={hero} />
}
