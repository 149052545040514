import React from "react"
import { graphql } from "gatsby"

import { withoutAuthentication } from "../hoc/withAuthentication"
import { Wishlist as Page } from "../components/Wishlist/Wishlist"

export const query = graphql`
  query {
    page: sanitySavedPage {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      products: _rawNavigationProducts(resolveReferences: { maxDepth: 2 })
      inspiration: _rawNavigationInspiration(resolveReferences: { maxDepth: 2 })
      heroBanner: _rawHeroBanner(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default withoutAuthentication(({ data, ...props }) => <Page {...props} {...data} />)
